/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './assets/css/main.css';
import { Carousel } from 'react-responsive-carousel';
import IMG1 from './img/40.jpg';
import IMG2 from './img/41.jpg';
import IMG3 from './img/37.jpg';
import IMG4 from './img/39.jpg';
import IMG5 from './img/12.jpg'
import IMG6 from './img/13.jpg'
import IMG7 from './img/7.jpg';
import IMG8 from './img/8.jpg'
import IMG9 from './img/14.jpg'
import IMG10 from './img/15.jpg'
import Logo from './img/logo.png'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Form,Button,Spinner } from 'react-bootstrap';
import Component from '@reactions/component'
let arr = []

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      color: false,
      colo2: false,
      get_color2: '',
      name: '',
      gov: '',
      city: '',
      phone: '',
      size: '',
      count: 1,
      s40: false,
      s1: false,
      s2: false,
      s3: false,
      s4: false,
      more: false,
      counts: [],
      items: [],
      prcie: 20000,
      price_t: 25000,
    }

    // let value = document.getElementById('countvalue').innerHTML
    // console.log(value);

  }

  get_slectedv2(id, size, cls, index) {
    console.log(id);
    let getClass = document.getElementsByClassName(`${`sizeC|${index}`}`);
    for (let index = 0; index < getClass.length; index++) {
      // getClass[index].style.display = "block";
      getClass[index].style.color = "#008dde";
      getClass[index].style.background = " #fff";
      getClass[index].style.border = "3px solid #008dde";
    }
    let get_slected = document.getElementById(id)
    get_slected.style.color = "#fff";
    get_slected.style.background = " #008dde";
    let obj = {
      id: index,
      size: size,
      color: ''
    }

    let ind = arr.find(e => e.id === index)
    if (ind) {

      arr.find(e => e.id === index).size = size;
      // console.log('newArr', arr.find(e => e.id == index));

      //arr.findIndex((obj => obj.id == index))
      // arr = arr.find(e => e.id == index)
      // arr[0].size=size;
      // arr = arr.filter(e => e.id != index)
      //  arr.push(obj)

      // this.setState({ items: found })
    } else {
      arr.push(obj)

      // this.setState({ items: found })
    }



  }
  select_size(id, size) {
    let getClass = document.getElementsByClassName(`'testc'`);
    console.log(getClass);
    for (let index = 0; index < getClass.length; index++) {
      // getClass[index].style.display = "block";
      getClass[index].style.color = "#008dde";
      getClass[index].style.background = " #fff";
      getClass[index].style.border = "3px solid #008dde";
    }
    let get_slected = document.getElementById(id)
    get_slected.style.color = "#fff";
    get_slected.style.background = " #008dde";
    this.setState({ size: size })
  }
  componentDidMount() {
    let ip = '', city, country;
    this.more('1')
    let check = localStorage.getItem('fistTime')
    if (!check) {
      localStorage.setItem('fistTime', 1)
      // Make a request for a user with a given ID
      axios.get('https://ipinfo.io?token=f01c1c47868f42')
        .then(function (response) {
          // handle success


          axios.post('https://api.lamha-iq.com/users/web/vist/', {
            city: response.data.city,
            country: response.data.country,
            ip: response.data.ip,
            type: "s",
          })
            .then(function (response) {
              console.log(response);
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })

    }




  }
  submit() {

    document.getElementById('spinnerdiv').style.display='flex'
    document.getElementById('submitbtn').style.display="none"

    // for (let index = 0; index < this.state.counts.length; index++) {
    //   let get_slected = document.getElementById(id)
    let name = document.getElementById('name').value;
    let phone = document.getElementById('phone').value;
    let city = document.getElementById('city').value;
    let gov = document.getElementById('gov').value;
    let notes = document.getElementById('notes').value;

    let items = []
    // }

    // let name = document.getElementById('name').value;
    // let phone = document.getElementById('phone').value;
    // let city = document.getElementById('city').value;
    // let gov = document.getElementById('gov').value;
    // let size = this.state.size
    // let color = this.state.get_color2

    if (!name) {
      window.alert('يرجى ادخال الاسم')

      document.getElementById('spinnerdiv').style.display='none'
      document.getElementById('submitbtn').style.display="flex"
  
      return -1;


    }
    if (phone.length !== 11) {
      window.alert('يرجى التأكد من رقم الهاتف')
      document.getElementById('spinnerdiv').style.display='none'
      document.getElementById('submitbtn').style.display="flex"
      return -1;
    }
    if (!gov || gov === "none") {
      window.alert('يرجى ادخال المحافظة')
      document.getElementById('spinnerdiv').style.display='none'
      document.getElementById('submitbtn').style.display="flex"
      return -1;
    }
    if (!city) {
      window.alert('يرجى ادخال المنطقة')
      document.getElementById('spinnerdiv').style.display='none'
      document.getElementById('submitbtn').style.display="flex"
      return -1;
    }
    if (arr.length > 0) {
      for (let index = 0; index < arr.length; index++) {
        if (arr[index].size === "" || arr[index].color === "") {
          window.alert('الرجاء التأكد من اختيار الون او القياس للمنتج')
          document.getElementById('spinnerdiv').style.display='none'
          document.getElementById('submitbtn').style.display="flex"
          return -1;
        } else {
          let name = `G27${arr[index].color}${arr[index].size}`

          let obj = {
            name: name,
            count: 1
          }
          let check = items.find(e => e.name === name)
          if (check) {
            items.find(e => e.name === name).count = items.find(e => e.name === name).count + 1
          } else {
            items.push(obj)
          }
        }

      }
    } else if (arr.length === 0) {
      window.alert('الرجاء التأكد من اختيار الون او القياس للمنتج')
      document.getElementById('spinnerdiv').style.display='none'
      document.getElementById('submitbtn').style.display="flex"
      return -1;
    }
    let price = this.state.price_t 
    let notes2 = ``;
    for (let index = 0; index < items.length; index++) {
      if (notes2.length === 0) {

        notes2 = ` (${items[index].count}  *  ${items[index].name}) `

      } else {

        notes2 = `${notes2} /  (${items[index].count}  *  ${items[index].name})`

      }

    }
    axios.post('https://api.lamha-iq.com/users/web/order/v2', {
      name: name,
      gov: gov,
      city: city,
      phone: phone,
      page: 18,
      price: price / 1000,
      notes: notes,
      items: items,
      notes2
    })
      .then(function (response) {
        // window.location.href = "/done";
        window.alert('تم الحجز بالنجاح شكرا لك سوف يصلك الطلب خلال 48 ساعة كحد اقصى')
        document.getElementById('spinnerdiv').style.display='none'
        document.getElementById('submitbtn').style.display="flex"
      })
      .catch(function (error) {
        document.getElementById('spinnerdiv').style.display='none'
        document.getElementById('submitbtn').style.display="flex"
        console.log(error);
      });
  }
  set_coler(value, index, id) {
    let blid = document.getElementById(`colorb${index}`);
    let wlid = document.getElementById(`colorw${index}`);
    let bwlid = document.getElementById(`colorbw${index}`);
    blid.style.border = " 1px solid #000";
    wlid.style.border = " 1px solid #000";

    bwlid.style.border = " 1px solid #000";
    if (value === 'b') {
      blid.style.border = " 4px solid #16b60c";

    }
    if (value === 'w') {
      wlid.style.border = " 4px solid #16b60c";

    }
    if (value === 'BL-WI') {
      bwlid.style.border = " 4px solid #16b60c";

    }

    // // wlid.style.border = "border: 1px solid #000"
    // console.log(blid);
    // if (value === 'b') {
    //   let getcolore = document.getElementsByClassName(`color${index}`)
    //   console.log(getcolore);
    //   for (let index = 0; index < getcolore.length; index++) {
    //     getcolore[index].style.border = "border: 1px solid #000"

    //   }
    //   let dd= document.getElementById(id)
    //   dd.style.border="5px solid #01dd77"
    //   // let cb = document.getElementById("bcolor")
    //   // let cw = document.getElementById("wcolor")
    //   // cb.className = "CheckColor1a"
    //   // cw.className = "CheckColor2"
    // }
    // if (value === 'w') {
    //   let getcolore = document.getElementsByClassName(`color${index}`)
    //   for (let index = 0; index < getcolore.length; index++) {
    //       // let dd= document.getElementById(`colorB${index}`)
    //     getcolore[index].style.border = "border: 1px solid #000"
    //   //  document.getElementById(`bcolorB${index}`).style.border = "border: 1px solid #000"

    //   }
    //   let dd= document.getElementById(id)
    //   console.log(dd);
    //   dd.style.border="5px solid #01dd77"
    // }


  }
  more(value) {
    var divs = []
    for (let index = 0; index < value; index++) {
      divs.push(
        <Component initialState={{
          count: 0, className: `btmn|${index}`, c: false, a: () => {


          }
        }} >
          {({ setState, state }) => (
            <div>
              <div key={index}>
                <div className="attr-detail attr-color mb-15" style={{ height: 100, disply: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <h4 style={{ paddingRight: 20, marginBottom: 10 }} className="mr-10">اللون</h4>
                  <ul className="list-filter color-filter" style={{ marginRight: 45 }}>
                    <li><div className={`CheckColor1 ${`color${index}`}`} id={`colorb${index}`} onClick={(e) => {

                      let ind = arr.find(e => e.id === index)
                      if (ind) {
                        arr.find(e => e.id === index).color = "BL";

                      } else {
                        let obj = {
                          id: index,
                          size: '',
                          color: 'BL'
                        }
                        arr.push(obj)
                      }
                      this.set_coler('b', index, e.target.id)
                      //  this.setState({ color1: true, color2: false, get_color2: "WI" })

                    }} /></li>
                    <li><div className={`CheckColor2 ${`color${index}`}`} id={`colorw${index}`} onClick={(e) => {

                      let ind = arr.find(e => e.id === index)
                      if (ind) {
                        arr.find(e => e.id === index).color = "WI";

                      } else {
                        let obj = {
                          id: index,
                          size: '',
                          color: 'WI'
                        }
                        arr.push(obj)
                      }
                      this.set_coler('w', index, e.target.id)
                      //    this.setState({ color1: false, color2: true, get_color2: "WI" })

                    }} /></li>
                    <li>
                      <div className={`CheckColor2 ${`color${index}`}`} style={{ background: "linear-gradient(to top, #f9fafb 50%, #333 50%)" }} id={`colorbw${index}`}
                        onClick={(e) => {
                          let ind = arr.find(e => e.id === index)
                          if (ind) {
                            arr.find(e => e.id === index).color = "BL-WI";

                          } else {
                            let obj = {
                              id: index,
                              size: '',
                              color: 'BL-WI'
                            }
                            arr.push(obj)
                          }
                          this.set_coler('BL-WI', index, e.target.id)
                          //    this.setState({ color1: false, color2: true, get_color2: "WI" })

                        }} >
                        {/* <div style={{backgroundColor:'black' ,width:'50%',height:'100%'}}></div>
                    <div style={{backgroundColor:'#ffff' ,width:'50%',height:'100%'}}></div> */}
                      </div>

                    </li>
                  </ul>
                </div>
                <div className="attr-detail attr-size" style={{ height: 100, disply: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <h4 style={{ paddingRight: 20, marginBottom: 10 }} className="mr-10">اختر القياس</h4>
                  <ul className="list-filter size-filter font-small" style={{ marginRight: 8 }} id={`s`}>
                    <li>
                      <div className={`${'Checks1'} ${`sizeC|${index}`}`}
                        id={`${index}checked40`}
                        onClick={(e) => {
                          state.a();
                          this.get_slectedv2(e.target.id, 40, state.className, index)
                        }}
                      >40</div>
                    </li>
                    <li>
                      <div className={`${'Checks1'} ${`sizeC|${index}`}`}
                        id={`${index}checked41`}
                        onClick={(e) => {
                          this.get_slectedv2(e.target.id, 41, state.className, index)

                        }}
                      >41</div></li>
                    <li><div className={`${'Checks1'} ${`sizeC|${index}`}`}
                      id={`${index}checked42`}
                      onClick={(e) => {
                        this.get_slectedv2(e.target.id, 42, state.className, index)
                      }}
                    >42</div></li>
                    <li><div className={`${'Checks1'} ${`sizeC|${index}`}`}
                      id={`${index}checked43`}
                      onClick={(e) => {
                        this.get_slectedv2(e.target.id, 43, state.className, index)

                      }}
                    >43</div></li>
                    {/* <li><div className={`${'Checks1'} ${`sizeC|${index}`}`}
                      id={`${index}checked44`}
                      onClick={(e) => {
                        this.get_slectedv2(e.target.id, 44, state.className, index)

                      }}
                    >44</div></li> */}
                  </ul>
                </div>
              </div>
              <hr />
            </div>
          )}
        </Component>

      )
    }
    this.setState({ counts: divs })

  }

  render() {
    return (
      <div className="App">

        <main className="main">
          <div className="page-header breadcrumb-wrap" style={{ display: 'felx', alignItems: "center", }}>
            <center>
              <img width={75} height={75} src={Logo} alt="" />
              <h3>Liger.fashion</h3>
            </center>

          </div>
          <section className="mt-60 mb-60">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="product-detail accordion-detail">
                    <div className="row mb-50">
                      <div className="col-md-6 col-sm-12 col-xs-12">

                        <Carousel autoPlay infiniteLoop >
                          <div>
                            <img src={IMG1} alt='im' />
                          </div>
                          <div>
                            <img src={IMG2} alt='im' />
                          </div>
                          <div>
                            <img src={IMG3} alt='im' />
                          </div>
                          <div>
                            <img src={IMG4} alt='im' />
                          </div>
                          <div>
                            <img src={IMG5} alt='im' />
                          </div>
                          <div>
                            <img src={IMG6} alt='im' />
                          </div>
                          <div>
                            <img src={IMG7} alt='im' />
                          </div>
                          <div>
                            <img src={IMG8} alt='im' />
                          </div>
                          <div>
                            <img src={IMG9} alt='im' />
                          </div>

                        </Carousel>
                        <div className="single-social-share clearfix mt-50 mb-15">
                          <div className="mobile-social-icon wow fadeIn  mb-sm-5 mb-md-0 animated">
                            <a className="facebook" href="https://www.facebook.com/ligerfashionfootwear"><i className="fab fa-facebook-f"></i></a>

                            <a className="instagram" href="https://www.instagram.com/Liger.fashion/"><i className="fab fa-instagram"></i></a>
                          </div>
                        </div>

                      </div>
                      <div className="col-md-6 col-sm-12 col-xs-12" style={{ textAlign: "right" }} >
                        <div className="detail-info" >
                          <h2 className="title-detail">برادا فيتنامي</h2>
                          <div style={{ width: '100%', disply: 'flex', justfyContent: 'flex-end' }}>
                            <div className="product-detail-rating" >

                              <div className="product-rate-cover text-end">
                                <div className="product-rate d-inline-block">
                                  <div className="product-rating" style={{ width: '90%' }}>
                                  </div>
                                </div>
                                <span className="font-small ml-5 text-muted"> (250k reviews)</span>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix product-price-cover">
                            <div className="product-price primary-color float-left">
                              <ins><span className="text-brand">دينار عراقي 20,000 </span></ins>
                              <ins><span className="old-price font-md ml-15">دينار عراقي 30,000 </span></ins>
                              <div className="save-price  font-md color3 ml-15" style={{ color: 'red', fontSize: '25px', marginTop: '20px' }}>25% خصم</div>
                            </div>
                          </div>
                          <div className="bt-1 border-color-1 mt-15 mb-15"></div>
                          <div className="short-desc mb-30">
                            <p>
                              برادا فيتنامي  الصنع يعني طبي وما يسوي ريحة والاهم من هذا خفيف الوزن ومريح ومتوفر بثلاث الوان  سعر القطعة 20 الف وسعر التوصيل بغداد والمحافظات 5 الاف فقط القياسات ستاندر 40-41-42-43-44 للحجز يرجى ملى المعلومات المطلوبه , توصيل بغداد خلال 24 ساعة المحافظات خلال 48 ساعه من تاريخ الحجز
                            </p>
                          </div>

                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="الاسم" id="name" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>الاسم  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            {/* <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="المحافظة" id="gov" /> */}

                            <Form.Select id="gov" style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} >
                              <option value="none">اختر محافظة</option>
                              <option value="بغداد">بغداد</option>
                              <option value="بابل">بابل</option>
                              <option value="الأنبار">الأنبار</option>
                              <option value="البصرة">البصرة</option>
                              <option value="دهوك">دهوك</option>
                              <option value="القادسية">القادسية</option>
                              <option value="ديالى">ديالى</option>
                              <option value="ذي قار">ذي قار</option>
                              <option value="السليمانية">السليمانية</option>
                              <option value="صلاح الدين">صلاح الدين</option>
                              <option value="كركوك">كركوك</option>
                              <option value="كربلاء">كربلاء</option>
                              <option value="المثنى">المثنى</option>
                              <option value="النجف">النجف</option>
                              <option value="نينوى">نينوى</option>
                              <option value="واسط">واسط</option>
                              <option value="ميسان">ميسان</option>
                              <option value="اربيل">اربيل</option>
                              <option value="موصل">موصل</option>
                            </Form.Select>
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>المحافظة  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="المدينة" id="city" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>المدينة  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"number"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="الهاتف" id="phone" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>الهاتف  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="ملاحظات" id="notes" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>ملاحظات</h5>

                          </div>
                          <div className="attr-detail attr-size" style={{ height: 100, disply: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                            <h5 className="mr-10" style={{ textAlign: 'center', padding: 10 }}>عدد القطع</h5>
                            <ul className="list-filter size-filter font-small" style={{ marginRight: 40 }}>
                              <li>
                                <div className={'decDiv'}
                                  id="decDiv"
                                  onClick={(e) => {
                                    let value = document.getElementById('countvalue').innerHTML
                                    if (Number(value) > 1) {
                                      let newnew = Number(value) - 1
                                      document.getElementById('countvalue').innerHTML = newnew
                                     
                                      if (arr.length > 1) {
                                        arr.length = arr.length - 1

                                      }
                                      let price = (this.state.prcie * newnew) + 5000
                                      console.log(price);
                                      this.setState({ price_t: price })
                                      this.more(newnew)
                                    }
                                  }}

                                >-</div>
                              </li>
                              <li>
                                <p className={"count"} id="countvalue">1</p></li>
                              <li><div className={"incDiv"}
                                id="incDiv"
                                onClick={(e) => {
                                  this.setState({ more: true })
                                  let value = document.getElementById('countvalue').innerHTML
                                  let newvalue = Number(value) + 1
                                  document.getElementById('countvalue').innerHTML = newvalue
                                  let price = (this.state.prcie * newvalue) + 5000
                                  this.setState({ price_t: price })
                                  this.more(newvalue)

                                }}
                              >+</div></li>


                            </ul>
                          </div>

                          {
                            this.state.counts
                          }


                          <div className="bt-1 border-color-1 mt-30 mb-30"></div>
                          <div>
                            <div className="attr-detail attr-size" style={{ height: 100, disply: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                              <h5 className="mr-10" style={{ textAlign: 'center', padding: 10 }}>: مجموع الحساب الكلي مع التوصيل </h5>
                              <h5 className="mr-10" style={{ textAlign: 'center', padding: 10, direction: 'rtl' }}> IQD <span>{this.state.price_t}</span>  </h5>

                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            
                          <div id="spinnerdiv"  style={{display:'none'}}>
                          <Button variant="primary" style={{borderRadius:'35px'}} disabled>
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
    />
   ...  تحميل
  </Button>
                          </div>

                            <button type="submit" id="submitbtn" className="button button-add-to-cart"
                              onClick={() => {
                                this.submit()
                                // console.log(this.state.size, this.state.get_color2);
                              }}
                            >احجز الان</button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Home;
